#SpoxPOEmailViewer-outer {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  cursor: pointer;
}

#SpoxPOEmailViewer-outer .inner {
  position: absolute;
  background: white;
  border-radius: 10px;
  width: 80%;
  top: 50%;
  height: 65%;
  padding: 10px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  overflow: auto;
  cursor: unset;
}

#SpoxPOEmailViewer-outer .inner .title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}

#SpoxPOEmailViewer-outer .inner .topInfo {
  line-height: 1.4em;
}
