:root {
  --primary-color: #0000fe;
  --bg-color: white;
  --text-color: black;
  --content-border-color: #ccc;
  --content-border-darkerColor: #999;
  --table-odd-row-color: #d7e6f5;
  --table-row-hover-color: #9abcdf;
  --danger-color: #dc3545;
  --success-color: #28a745;
}

body {
  margin: 0px;
  padding: 0px;
  font-size: 13px;
}
html,
body,
#root {
  height: 100%;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.breadcrumbs .breadcrumb {
  color: var(--primary-color);
  text-decoration: underline;
  cursor: pointer;
}

.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 1;
  z-index: 100;
  background: var(--light-color);
  color: #333;
  position: fixed;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
  background: var(--light-color);
  color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: var(--success-color);
  color: #fff;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
