.tableDisplay table {
  width: 100%;
}
.tableDisplay thead {
  font-weight: bold;
}
.tableDisplay thead td {
  border-bottom: 1px solid var(--text-color);
}
.tableDisplay thead td .sortBy {
  position: relative;
  top: 0px;
  padding-left: 5px;
  opacity: 0.3;
}
.tableDisplay thead td .sortBy.active {
  opacity: 1;
}
.tableDisplay tbody tr:nth-child(odd) {
  background: var(--table-odd-row-color);
}
.tableDisplay tbody tr.odd {
  background: var(--table-odd-row-color);
}
.tableDisplay tbody tr.even {
  background: unset;
}
.tableDisplay tbody tr:hover {
  background: var(--table-row-hover-color);
}
.tableDisplay td {
  text-align: left;
  line-height: 1.4em;
}
.tableDisplay td:last-of-type {
  text-align: right;
}
.tableDisplay tbody td:first-child {
  padding-left: 5px;
}
.tableDisplay tbody td:last-child {
  padding-right: 5px;
}
