.vbr_itemSearch-outer {
  background-position: fixed;
  height: 100%;
}
.vbr_itemSearch-outer .header-outer {
  height: 30px;
}
.vbr_itemSearch-outer .vbr-inner {
  padding: 0px 10px 0px 10px;
}
