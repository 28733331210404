.vbr_spoxPOStatus-outer {
  background-position: fixed;
  height: 100%;
}
.vbr_spoxPOStatus-outer .header-outer {
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
}
.vbr_spoxPOStatus-outer .header-outer span {
  padding: 0px 10px;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}
.vbr_spoxPOStatus-outer .vbr-inner {
  padding: 0px 10px;
}
.vbr_spoxPOStatus-outer .vbr-inner #top-outer {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  flex-wrap: wrap;
}
.vbr_spoxPOStatus-outer .vbr-inner #top-outer .flexDiv {
  flex: 1;
  margin-bottom: 20px;
  min-width: 525px;
}
.vbr_spoxPOStatus-outer .vbr-inner .poPreview-top {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.vbr_spoxPOStatus-outer .vbr-inner .poPreview-top .title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 3px;
}
.vbr_spoxPOStatus-outer .vbr-inner .poPreview-top .lastUpdated {
  font-size: 12px;
}

.vbr_spoxPOStatus-outer .vbr-inner .emailLogTable tr:nth-child(odd) {
  background: #daecf5;
}
