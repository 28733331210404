.vbr_itemSearch-outer .vbr-inner .secTitle {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}
.vbr_itemSearch-outer .vbr-inner .singleProductSearch-outer {
  display: flex;
}
.vbr_itemSearch-outer .vbr-inner .options-flex {
  display: flex;
  justify-content: flex-start;
  flex: 0 1 50%;
}

.vbr_itemSearch-outer .vbr-inner .options-flex select {
  width: inherit;
}

.vbr_itemSearch-outer .vbr-inner .singleProductSearch-outer input[type="text"] {
  flex: 1;
  background: var(--bg-color);
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border: 1px solid var(--text-color);
  height: 25px;
  padding: 0px;
  padding-left: 5px;
}
.vbr_itemSearch-outer
  .vbr-inner
  .singleProductSearch-outer
  #searchBarPartNumber {
  flex: 0 0.2;
  background: var(--bg-color);
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border: 1px solid var(--text-color);
  height: 25px;
  padding: 0px;
  padding-left: 5px;
}

.vbr_itemSearch-outer
  .vbr-inner
  .singleProductSearch-outer
  input[type="select"] {
  border: 1px solid var(--text-color);
  background: var(--bg-color);
  border-radius: 0px;
  height: 25px;
}
.vbr_itemSearch-outer .vbr-inner .singleProductSearch-outer button {
  flex: 0 0 100px;
  background: var(--primary-color);
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border: 1px solid var(--primary-color);
  color: var(--bg-color);
  height: 27px;
  cursor: pointer;
}
.vbr_itemSearch-outer .vbr-inner .singleProductSearch-outer button:hover {
  opacity: 0.7;
}

.vbr_itemSearch-outer .vbr-inner .searchAsTypeAnchor-outer {
  position: relative;
}
.vbr_itemSearch-outer .vbr-inner .searchAsTypeAnchor-outer.hide {
  display: none;
}
.vbr_itemSearch-outer .vbr-inner .searchAsTypeAnchor {
  background: white;
  position: absolute;
  top: -1;
  width: 100%;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border: 1px solid #0d2c54;
  line-height: 2em;
  padding-left: 5px;
  color: black;
  z-index: 25;
  font-size: 13px;
  font-weight: normal;
}
.vbr_itemSearch-outer .vbr-inner .searchAsTypeAnchor.hide {
  display: none;
}
.vbr_itemSearch-outer .vbr-inner .searchAsTypeAnchor div {
  font-weight: bold;
  cursor: pointer;
}
.vbr_itemSearch-outer .vbr-inner .searchAsTypeAnchor div:hover {
  background: #dcebfd;
}
.vbr_itemSearch-outer .vbr-inner .singleSearchAdvancedOptions-outer.hide {
  display: none;
}
.vbr_itemSearch-outer .vbr-inner .singleSearchAdvancedOptions-outer {
}
.vbr_itemSearch-outer .vbr-inner .options-flex-outer {
}
.vbr_itemSearch-outer .vbr-inner .options-flex {
  padding-top: 10px;
  display: flex;
  justify-content: flex-start;
  flex: 0 1 50%;
}
.vbr_itemSearch-outer .vbr-inner .options-flex .label {
  flex: 0 0 150px;
}
.vbr_itemSearch-outer .vbr-inner .options-flex select {
  width: inherit;
}
.vbr_itemSearch-outer .vbr-inner .toggleAdvancedOptions {
  padding-top: 5px;
  text-align: right;
}
.vbr_itemSearch-outer .vbr-inner .toggleAdvancedOptions span {
  cursor: pointer;
  color: blue;
}
.vbr_itemSearch-outer .vbr-inner .singleProdSearchResult-outer {
  padding-top: 15px;
}
.vbr_itemSearch-outer .vbr-inner .searchProd_title-outer .secFeedback {
  font-size: 14px;
  font-weight: bold;
  background: var(--table-odd-row-color);
  border-radius: 5px;
  padding: 13px 10px 10px 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  opacity: 0.7;
}
.vbr_itemSearch-outer .vbr-inner .searchProd_title-outer .secTitle {
  padding-top: 3px;
}
.vbr_itemSearch-outer
  .vbr-inner
  .searchProd_title-outer
  .secFeedback
  .secFeedback-float {
  display: flex;
  font-size: 18px;
  padding-left: 10px;
  position: relative;
  top: -2px;
}
.vbr_itemSearch-outer
  .vbr-inner
  .searchProd_title-outer
  .secFeedback
  .secFeedback-float
  .far {
  margin-left: 10px;
  cursor: pointer;
}
.vbr_itemSearch-outer
  .vbr-inner
  .searchProd_title-outer
  .secFeedback
  .secFeedback-float
  .fa-thumbs-up:hover {
  color: green;
}
.vbr_itemSearch-outer
  .vbr-inner
  .searchProd_title-outer
  .secFeedback
  .secFeedback-float
  .fa-thumbs-down:hover {
  color: red;
}

.vbr_itemSearch-outer .vbr-inner .resultTable-outer {
  margin-top: 15px;
}
.vbr_itemSearch-outer .vbr-inner .resultTable-outer table {
  width: 100%;
}
.vbr_itemSearch-outer .vbr-inner .resultTable-outer table tbody tr {
  cursor: pointer;
}
.vbr_itemSearch-outer .vbr-inner .resultTable-outer table tbody td a,
.vbr_itemSearch-outer .vbr-inner .resultTable-outer table tbody td a:visited,
.vbr_itemSearch-outer .vbr-inner .resultTable-outer table tbody td a:active {
  color: var(--text-color);
  text-decoration: none;
}
.vbr_itemSearch-outer .vbr-inner .resultTable-outer table .pricingLoading {
  text-align: center;
  padding: 20px;
}
